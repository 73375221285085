
.queue-container-bigger {
    width: 225px;
    /* margin: auto; */
    /* background-color: #f0f0f0; */
    background-color: rgba(255, 255, 255, 0.2);
    /* White with 50% transparency */
    backdrop-filter: blur(8px); /* Blur effect */



    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    /* justify-content: flex-start; */
    display: inline-block;
    /* flex-direction: column;  */
    /* Ensures the title is at the top */
    /* overflow-y: auto; */
    /* Adds scrolling */
    /* TODO: better height that fits the whole screen*/
    margin: 0 1%;
    margin-top: 1%;
    height: 100%;
    text-align: center;

}
/* TODO: take out this div and move content into the items container*/
.queue-container {
    /* padding: 20px; */
    display: inline-block;
    /* text-align: center; */

    overflow-y: auto;
    margin-top: 1%;
    height: 100%;

}

.flashing-border{
    /*animation: flash-border 1s infinite; /* Apply the animation */
    animation: pop 1s infinite; /* Apply the animation when this class is active */
}
.popping-queue{
    /*animation: flash-border 1s infinite; /* Apply the animation */
    animation: pop 1s infinite; /* Apply the animation when this class is active */
}
@keyframes pop {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.01);
    }
}
.popping-student-card{
    /*animation: flash-border 1s infinite; /* Apply the animation */
    animation: pop-card 1s infinite; /* Apply the animation when this class is active */
    cursor: pointer;

}
@keyframes pop-card {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.04);
    }
}
@keyframes flash-border {
    0% {
        border-color: red;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: red;
    }
}

.queue-title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.items-container {
    display: flex;
    flex-direction: column; /* New items appear at the bottom*/
    align-items: center;
    overflow: hidden; /* Hide overflowing content */
    gap: 10px;
}


.queue-item {
    background-color: rgba(255, 255, 255, 0.5);
    /* White with 50% transparency */
    /* hide overflow content */


    /* background-color: #fff; White background for items */
    padding: 0 13px;
    margin: 3px 3px;
    align-items: center;
    width: 170px;
    border-radius: 8px; /* Rounded corners for items */
    box-shadow: 0 2px 4px rgba(0,0,0,0.05); /* Lighter shadow for items */
}

.item-name {
    font-size: 12px;
    color: #000;
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
}
.item-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.x-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    /* width: 10px; */
}
.item-location {
    font-size: 14px;
    color: #666;
    /* margin-bottom: 10px; */
    overflow-wrap: break-word;  /* Ensures words break to prevent overflow */
    word-wrap: break-word;  /* Older browsers support */
    word-break: break-word;
    width: 40px;
    overflow:hidden;
    text-overflow:ellipsis;
    pointer-events: all;
}
.item-pref {
    font-size: 14px;
    color: #666;
    /* margin-bottom: 10px; */
    overflow-wrap: break-word;  /* Ensures words break to prevent overflow */
    word-wrap: break-word;  /* Older browsers support */
    word-break: break-word;
    width: 100px;
    overflow:hidden;
    text-overflow: ellipsis;
    pointer-events: all;
}

.item-professor-preference {
    font-size: 14px;
    color: #666;
    /* margin-bottom: 10px; */
}