.welcome-content {
    display: flex;
    flex-direction: column; /* Stack items on top of each other by default (mobile mode) */
    justify-content: space-around;
    gap: 10%;
}

@media (min-width: 768px) { /* Adjust the breakpoint as needed */
    .welcome-content {
        flex-direction: row; /* Place items next to each other in web mode */
    }
}