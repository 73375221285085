/* Overall container adjustments */
.container {
    max-width: 100%;
    margin: 20px 40px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Title styles */
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  /* Form styling */
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Input field styling */
  .input {
    padding: 12px;
    /* margin-bottom: 20px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  /* Label styling */
  .label {
    font-size: 16px;
    color: #666;
    margin-bottom: 5px;
    margin-top: 10px;

  }

  /* Button styling */
  .button {
    padding: 12px 25px;
    font-size: 18px;
    color: #F8F8F8;
    background-color: #282c34;
    border: none;
    border-radius: 25px;
    margin-top:10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #282c34;
      color: #F8F8F8;
    }
  }

  .button:hover {
    background-color: #F8F8F8;
    color: #282c34;
  }

  /* Error modal styling */
  .error-modal {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
.flashing-border{
    /*animation: flash-border 1s infinite; /* Apply the animation */
    animation: pop 1s infinite; /* Apply the animation when this class is active */
}
@keyframes flash-border {
    0% {
        border-color: red;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: red;
    }
}
@keyframes pop {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.01);
    }
}