.radio-button {
    position: relative;
    width: 10px;
    height: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid black;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  .radio-button:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: #282c34; /* Change this to the desired color */
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }