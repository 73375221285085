.askName-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(40, 44, 52, 0.8);
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    z-index: 9999;
}

.text {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    margin-top: 17%; /* Adjust this value to move the div upwards */
}

.text-smaller {
    font-size: 18px;
    color: #b6b6b6;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 5%
}


.buttons-section {
    display: flex;
    padding: 30px;
}