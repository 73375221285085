
.root {
  overflow-x: scroll;
  /* background-color: black; */
  background-image: url('../assets/images/Medford-Library');
  background-size: cover; /* Cover the entire screen */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  /* Picture */
  display: grid;
  grid-template-rows: 1fr 5fr; /* First row is 5 times the height of the others */
  /* height: 100%; */
  height: 100vh; /* Use 100vh to ensure the container takes up the full viewport height */
  user-select: none; /* Standard syntax */


}


.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;

  padding: 0% 5%;
  padding-top: 3%;
  padding-bottom: 1%;

}

.Main_q_button {
    text-align: center;
}

.bigger-header  {
    background-color: rgba(255, 255, 255, 0.5); /* White with 50% transparency */
    backdrop-filter: blur(8px); /* Blur effect */
    padding-bottom: 2%;
    margin-bottom: 2%;
    border-radius: 0 0 20px 20px; /* Rounded corners for the bottom-left and bottom-right */

}

.App {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
